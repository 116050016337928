<template>
<div>
    <!-- <v-row v-show="$vuetify.breakpoint.mobile"></v-row>
    <v-row style="margin-top: 0%" v-show="!$vuetify.breakpoint.mobile"></v-row> -->
    <waiting-modal :title="title" :status="status" :active="active_waiting" @statusdailog="update_status_wait"></waiting-modal>
    <v-container>
        <v-row class="align-center d-flex h-0 pb-20" style="justify-content: center;">
            <v-col xl="3" lg="4" sm="8">
                <v-row class="d-flex">
                    <v-col col="12" style="text-align: center">
                        <span class="text-purple" style="font-weight: 600; font-size: 20px">เพิ่มข้อมูลสุขภาพ</span>
                    </v-col>
                </v-row>
                <br />
                <v-row class="d-flex">
                    <v-col col="12">
                        <v-card style="border-radius: 15px" elevation="1">
                            <v-card-text>
                                <v-row class="">
                                    <v-col cols="2">
                                        <v-icon color="purple">
                                            person
                                        </v-icon>
                                    </v-col>
                                    <v-col cols="10" style="text-align: left;padding-top: 26px;">
                                        <v-row><span class="pb-1">{{res_info.fullname}}</span></v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="d-flex">
                    <v-col col="12">
                        <v-card style="border-radius: 15px" elevation="1">
                            <v-card-text>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="4" class="form-title" >น้ำหนัก</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-text-field v-model="weight" type="number"  label="" number hide-details dense ></v-text-field>
                                        </v-col>
                                        <v-col cols="3" style="align-self: end;">Kg</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" class="form-title" >ความดัน</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-row>
                                                <v-col style="padding-right: 0px;">
                                                    <v-text-field v-model="systolic" type="number"  label=""  hide-details dense ></v-text-field>
                                                </v-col>
                                                <v-col cols="1" style="align-self: center;">/</v-col>
                                                <v-col style="padding-left: 0px;">
                                                    <v-text-field v-model="diastolic" type="number"  label=""  hide-details dense ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: end;">mmHg</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" class="form-title" >ระดับน้ำตาลในเลือด</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-text-field v-model="sugar" type="number" label="" hide-details dense ></v-text-field>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: end;">mg/dL</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" class="form-title" >ชีพจร</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-text-field v-model="hr" type="number"  label="" hide-details dense ></v-text-field>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: end;">ครั้ง/นาที</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" class="form-title">ชีพจรสม่ำเสมอ</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-select v-model="regular_hr" type="text" :items="[
                        'สม่ำเสมอ',
                        'ไม่สม่ำเสมอ',
                        'ไม่ทราบ'
                      ]" :rules="[(v) => !!v || 'โปรดเลือก']" label="" hide-details dense ></v-select>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: center;"></v-col>
                                    </v-row>

                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
        <v-row v-show="!$vuetify.breakpoint.mobile" class="align-center" justify="center" style="padding-top: 15px">
            <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                <v-icon small>west</v-icon>
            </v-btn>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                ยืนยันข้อมูล
            </v-btn>
        </v-row>

        <v-footer v-show="$vuetify.breakpoint.mobile" fixed class="action-nav" style="height: 60px">
            <v-row class="align-center" justify="center">
                <v-btn @click="$router.go(-1)" outlined style="border-color: #d6d6d629" color="purple" class="border-radius-xl">
                    <v-icon small>west</v-icon>
                </v-btn>
                &nbsp; &nbsp; &nbsp; &nbsp;
                <v-btn @click="validate" style="width: 250px" color="bg-primary text-white" class="border-radius-xl">
                    ยืนยันข้อมูล
                </v-btn>
            </v-row>
        </v-footer>
    </v-container>
</div>
</template>

<script>
import WaitingModal from "@/components/WaitingModal.vue";
import {
    APIURL
} from '@/constants'
import Vue from 'vue'
export default {
    name: "calendar-patient",
    components: {
        WaitingModal,
    },
    data: () => ({
        wRules: [
            v => (v > 30 && v < 220) || "โปรดกรอกจำนวนที่ถูกต้อง"
        ],
        res_info: {},
        active_waiting: false,
        weight: null,
        systolic: null,
        diastolic: null,
        hr: null,
        regular_hr: 'สม่ำเสมอ',
        sugar: null,
        title: '',
        status: '',
        selected_row: "",
        valid: true,
        name: "",
        nameRules: [
            (v) => !!v || "โปรดกรอกข้อมูล",
        ],
        email: "",

    }),
    created() {
        
        this.getUserInfo()
        
    },

    // mounted() {console.log(this.$store.state.qr_patient_id)},
    watch: {},
    methods: {
        update_status_wait(val) {
            this.active_waiting = val
        },
        async submitVal() {

            this.active_waiting = true
            this.title = 'กำลังเพิ่มข้อมูล',
            this.status = 'loading'

            
            //console.log(this.weight)
            //console.log(this.systolic)
            //console.log(this.diastolic)
            //console.log(this.hr)
            //console.log(this.regular_hr)
            //console.log(this.sugar)

            await Vue.axios.post(APIURL + '/add_patient_health_record', {
                "user_id": this.$store.state.qr_patient_id,
                "weight": this.weight ? this.weight : 0,
                "systolic": this.systolic ? this.systolic : 0,
                "diastolic": this.diastolic ? this.diastolic : 0,
                "hr": this.hr ? this.hr : 0,
                "regular_hr": this.regular_hr,
                "sugar": this.sugar ? this.sugar : 0,
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((response) => {
                if (response.data) {
                    this.title = 'เพิ่มข้อมูลสำเร็จ';
                    this.status = 'done'
                    setTimeout(() => {
                        this.active_waiting = false
                        this.$router.push('/home_checkmong')
                    }, 3000)
                } else {
                    this.active_waiting = false
                    this.title = 'ระบบขัดข้อง',
                        this.status = 'fail'
                }

            })

        },
        getUserInfo() {
            this.res_info = this.$store.state.qr_patient_info
            //console.log("------------",this.res_info)
        },
        validate() {
            const res = this.$refs.form.validate();
            if (res) {
                this.submitVal()
            }

        },
        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}

.action-nav {
    background: #ffffff !important;
    box-shadow: 0px -2px 4px rgba(207, 202, 202, 0.25) !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
    height: 77 px !important;
}

.v-menu__content .v-list-item__title {
    font-size: 0.875rem;
    color: #ffffff;
    color: gray;
}

.custom-field {
    border-radius: 6px;
}

.v-picker__title {
    color: #ffffff;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
    padding: 16px;
    background-color: #a84192;
}

.v-main__wrap {
    padding-bottom: 90px;
}

.position-relative {
    position: relative;
}

.v-date-picker-table .v-btn.v-btn--active {
    color: #ffffff;
    background-color: #a84192;
}

.theme--light.v-date-picker-header .v-date-picker-header__value:not(.v-date-picker-header__value--disabled) button:not(:hover):not(:focus) {
    color: #a84192 !important;
}

.theme--light.v-btn.v-btn--icon {
    color: #a84192 !important;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 0px;
}

.v-text-field>.v-input__control>.v-input__slot>.v-text-field__slot {
    padding-left: 0px !important;
}
</style>


<!-- 
                <v-row class="d-flex">
                    <v-col col="12">
                        <v-card style="border-radius: 15px" elevation="1">
                            <v-card-text>
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="4" style="align-self: center;">น้ำหนัก</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-text-field v-model="weight" type="number" :rules="wRules" label="น้ำหนัก" number  dense outlined></v-text-field>
                                        </v-col>
                                        <v-col cols="3" style="align-self: center;">Kg</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" style="align-self: center;">ความดัน</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-row>
                                                <v-col style="padding-right: 0px;">
                                                    <v-text-field v-model="systolic" type="number" :rules="nameRules" label=""  dense outlined></v-text-field>
                                                </v-col>
                                                <v-col cols="1" style="align-self: center;">/</v-col>
                                                <v-col style="padding-left: 0px;">
                                                    <v-text-field v-model="diastolic" type="number" :rules="nameRules" label=""  dense outlined></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: center;">mmHg</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" style="align-self: center;">ระดับน้ำตาลในเลือด</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-text-field v-model="sugar" type="number" :rules="nameRules" label="" hide-details dense outlined></v-text-field>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: center;">mg/dL</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" style="align-self: center;">ชีพจร</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-text-field v-model="hr" type="number" :rules="nameRules" label="" hide-details dense outlined></v-text-field>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: center;">ครั้ง/นาที</v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col cols="4" style="align-self: center;">ชีพจรเสม่ำเสมอ</v-col>
                                        <v-col cols="5" style="padding-left: 0px;padding-right: 0px;">
                                            <v-select v-model="regular_hr" type="text" :items="[
                        'สม่ำเสมอ',
                        'ไม่สม่ำเสมอ'
                      ]" :rules="[(v) => !!v || 'โปรดเลือก']" label="" hide-details dense outlined></v-select>
                                        </v-col>
                                        <v-col cols="3" style="padding-right: 0px;align-self: center;">mmHg</v-col>
                                    </v-row>

                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>


                 -->